/* FONTS */
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

/* NAVBAR */
.navbar {
    font-family: 'Montserrat', sans-serif;
    padding: 0 !important;
}

.navbar-nav {
    flex-wrap: wrap;
    justify-content: space-between;
}

.nav-link {
    color: #333333 !important;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: normal;
    height: 52px;
    display: flex !important;
    align-items: center !important;
}

.nav-link:hover {
    color: #e9500e !important;
    font-size: 15px;
    height: 51px;
    border-top: 1px solid #e9500e;
}

.container {
    border-bottom: none !important;
    max-width: 1200px !important;
    margin: 0 auto !important;
}

.dropdown-menu.show {
    padding-top: 0 !important;
    border-top: 2px solid #e9500e !important;
}

.dropdown-item {
    font-size: 13px !important;
}

.dropdown-item:first-child {
    border-top: 1px solid #e9500e;
    padding-top: 8px;
}

.dropdown-item:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.dropdown-item:hover {
    background-color: transparent !important;
}

#storeLink:hover .shopping-icon {
    color: #000;
}

.shopping-icon {
    color: #e9500e;
    margin-right: 0.5rem;
}

@media only screen and (max-width: 1080px) and (min-width: 801px) {
    .navbar-nav {
      flex-wrap: wrap;
      justify-content: center;
    }
}
  