@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

footer {
  position: absolute;
  clear: both;
  width: 100%;
  height: auto;
  background: #e9500e;
  min-height: 40px;
  font-family: 'Open Sans', sans-serif;
}

.footer-container {
  padding: 18px 0;
  width: 85%;
  margin: 0 auto;
}

.footer-socials {
  display: flex !important;
  align-items: center;
  justify-content: right;
}

.footer-socials--icons {
  margin: 0 10px;
  font-size: 16px;
}

.footer-copyright {
  display: inline;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 0 10px;
}

.footer-copyright--top {
  margin: 12px 0;
}
.footer-copyright--bottom {
  margin-bottom: 12px;
}

.footer-copyright--link {
  text-decoration: underline;
  color: #fff;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    background: #e9500e;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-container {
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
  }

  .footer-copyright {
    text-align: center;
  }

  .footer-socials {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .footer-socials--icons {
    margin: 0 10px;
    font-size: 16px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    background: #e9500e;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-container {
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
  }

  .footer-copyright {
    text-align: center;
  }

  .footer-socials {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .footer-socials--icons {
    margin: 0 10px;
    font-size: 16px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    background: #e9500e;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-container {
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
  }

  .footer-socials {
    display: flex !important;
    align-items: center;
    justify-content: right;
  }

  .footer-socials--icons {
    margin: 0 10px;
    font-size: 16px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    background: #e9500e;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-container {
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
  }

  .footer-socials {
    display: flex !important;
    align-items: center;
    justify-content: right;
  }

  .footer-socials--icons {
    margin: 0 10px;
    font-size: 16px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }
}

/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 1500px) {
  .footer-links {
    display: inline;
    margin: 0 10px;
    font-size: 1rem;
  }
}
