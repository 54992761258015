.sidebar {
  left: 15px;
  width: 320px;
  position: absolute;
  max-height: calc(95% - 70px);
  z-index: 1;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  overflow: scroll;
  margin-top: 10px;
}

.sidebar .row {
  padding: 5px 0px;
  border-bottom: 1px solid #e2e2e2;
  margin: 0;
}

.selectedStore {
  padding: 0.75em 10px !important;
}
.selectedStore_title {
  font-size: 1rem;
  margin-right: 10px;
}

.selectedStore__details {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid #ccc;
  align-items: flex-start !important;
  overflow: scroll !important;
}

.selectedStore__storeName {
  font-weight: 500;
  font-size: 20px;
}

.selectedStore__storeAddressLines {
  font-size: 1rem;
}
.selectedStore__contact {
  margin-top: 10px;
  word-break: break-all;
}

.selectedStore__contact--phone {
  margin-right: 10px;
}

.selectedStore__contact--phone-icon {
  font-size: 1rem;
  margin-right: 10px;
}

.selectedStore__contact--fax {
  margin-right: 10px;
}

.selectedStore__contact--fax-icon {
  font-size: 1rem;
  margin-right: 10px;
}

.selectedStore__contact--website {
  margin-top: 10px;
  color: #363636;
  text-decoration: none;
}

.selectedStore__contact--website > a {
  margin-top: 10px;
  color: #363636;
  text-decoration: none;
}

.selectedStore__contact--website > a:hover {
  margin-top: 10px;
  color: #000;
  text-decoration: none;
}

.selectedStore__categories--title {
  font-size: 14px;

  margin-top: 15px;
}

.selectedStore__categories--logo-container {
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.selectedStore__categories--logo-container-logo {
  width: 35%;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.selectedStore__categories--logo-container-logo--KDIT-Gold {
  border: 1px solid #f5b400;
}

.selectedStore__categories--logo-container-logo--KDIT-Silver {
  border: 1px solid #585858;
}

.selectedStore__categories--logo-container-logo--KDIT-Platinum {
  border: 1px solid #6e3cbe;
}

.selectedStore__categories--logo-container-logo--KDIT-KEP {
  border: 1px solid #f06400;
}

.selectedStore__categories--logo-container-logo--KDIT-Supply-Distributor {
  border: 1px solid #282828;
}

.selectedStore__categories--logo-container-logo--KDIT-HW-Distributor {
  border: 1px solid #0a9bcd;
}

.selectedStore__certificates--title {
  font-size: 14px;

  margin-top: 5px;
}

.selectedStore__certificates--certificate {
  width: 100%;
}

.selectedStore__certificates--name {
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    left: 15px;
    width: 41%;
    position: absolute;
    max-height: calc(95% - 175px);
    z-index: 1;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    border-radius: 2px;
    overflow: scroll;
    margin-top: 10px;
  }
}
