html {
  height: 100%;
  background-color: #e8e8e8 !important;
}
@font-face {
  font-family: "GT-Eesti";
  src: url("./font/GT-Eesti-Pro-Display-Light.woff") format("woff");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "GT-Eesti";
}

input {
  font-family: "GT-Eesti";
}

#root {
  height: 100%;
  position: relative;
}
