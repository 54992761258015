@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);
html {
  height: 100%;
  background-color: #e8e8e8 !important;
}
@font-face {
  font-family: "GT-Eesti";
  src: url(/static/media/GT-Eesti-Pro-Display-Light.6d6481c9.woff) format("woff");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "GT-Eesti";
}

input {
  font-family: "GT-Eesti";
}

#root {
  height: 100%;
  position: relative;
}

.mapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* margin-top: calc(100% - 100px); */
  height: calc(100vh - 80px);
  margin-top: auto;
}

.floating-items {
  position: relative;
  /* width: 20%; */
  /* height: auto; */
  right: 40px;
  top: -52px;
  text-align: right;
  font-size: 14px;
}

.legend {
  position: absolute;
  right: 100px;
  text-align: center;
}

.legend-text {
  margin-top: 10px;
}
.legend-icon-container {
  background: #fff;
  /* height: 100%; */
  /* width: 100%; */
  color: gold;
  border: 1px solid #eee;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: auto;
  padding: 2px;
  font-size: 24px;
  box-shadow: -1px -1px 5px 2px #d8d6d6;
  cursor: pointer;
}

.legend-display {
  display: inline;
  position: absolute;
  right: 45px;
  text-align: left;
  top: 80px;
  padding: 10px 20px;
  background: white;
  border: 1px solid #fff;
  border-radius: 5px;
  z-index: 100;
}

.legend-display-items {
  display: flex;
  align-items: center;
  /* margin: 10px; */
  padding: -9px;
}

.legend-display-items:not(:first-child) {
  margin-top: 10px;
}

.legend-display-items > img {
  margin-right: 5px;
}

.back {
  position: absolute;
  right: -15px;
  text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mapContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* margin-top: calc(100% - 100px); */
    height: calc(100vh - 60px);
    margin-top: auto;
  }

  .floating-items {
    position: relative;
    /* width: 20%; */
    /* height: auto; */
    right: 20px;
    top: -52px;
    text-align: right;
    font-size: 14px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.sidebar {
  left: 15px;
  width: 320px;
  position: absolute;
  max-height: calc(95% - 70px);
  z-index: 1;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  overflow: scroll;
  margin-top: 10px;
}

.sidebar .row {
  padding: 5px 0px;
  border-bottom: 1px solid #e2e2e2;
  margin: 0;
}

.selectedStore {
  padding: 0.75em 10px !important;
}
.selectedStore_title {
  font-size: 1rem;
  margin-right: 10px;
}

.selectedStore__details {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid #ccc;
  align-items: flex-start !important;
  overflow: scroll !important;
}

.selectedStore__storeName {
  font-weight: 500;
  font-size: 20px;
}

.selectedStore__storeAddressLines {
  font-size: 1rem;
}
.selectedStore__contact {
  margin-top: 10px;
  word-break: break-all;
}

.selectedStore__contact--phone {
  margin-right: 10px;
}

.selectedStore__contact--phone-icon {
  font-size: 1rem;
  margin-right: 10px;
}

.selectedStore__contact--fax {
  margin-right: 10px;
}

.selectedStore__contact--fax-icon {
  font-size: 1rem;
  margin-right: 10px;
}

.selectedStore__contact--website {
  margin-top: 10px;
  color: #363636;
  text-decoration: none;
}

.selectedStore__contact--website > a {
  margin-top: 10px;
  color: #363636;
  text-decoration: none;
}

.selectedStore__contact--website > a:hover {
  margin-top: 10px;
  color: #000;
  text-decoration: none;
}

.selectedStore__categories--title {
  font-size: 14px;

  margin-top: 15px;
}

.selectedStore__categories--logo-container {
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.selectedStore__categories--logo-container-logo {
  width: 35%;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.selectedStore__categories--logo-container-logo--KDIT-Gold {
  border: 1px solid #f5b400;
}

.selectedStore__categories--logo-container-logo--KDIT-Silver {
  border: 1px solid #585858;
}

.selectedStore__categories--logo-container-logo--KDIT-Platinum {
  border: 1px solid #6e3cbe;
}

.selectedStore__categories--logo-container-logo--KDIT-KEP {
  border: 1px solid #f06400;
}

.selectedStore__categories--logo-container-logo--KDIT-Supply-Distributor {
  border: 1px solid #282828;
}

.selectedStore__categories--logo-container-logo--KDIT-HW-Distributor {
  border: 1px solid #0a9bcd;
}

.selectedStore__certificates--title {
  font-size: 14px;

  margin-top: 5px;
}

.selectedStore__certificates--certificate {
  width: 100%;
}

.selectedStore__certificates--name {
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    left: 15px;
    width: 41%;
    position: absolute;
    max-height: calc(95% - 175px);
    z-index: 1;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    border-radius: 2px;
    overflow: scroll;
    margin-top: 10px;
  }
}

.input {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  padding-right: none !important;
}

.autocomplete-input {
  width: 85%;
  border: none;
}

input:focus {
  outline: none;
}

.icon-search-active {
  margin-right: 20px !important;
}

.icon-close-inactive {
  display: none !important;
}

.icon-close-active {
  display: inline;
  margin-left: 10px !important;
}

.icon {
  pointer-events: initial !important;
  color: #000 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

::-webkit-input-placeholder {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px;
}

:-ms-input-placeholder {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px;
}

::-ms-input-placeholder {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px;
}

.is-medium.input {
  font-size: 1rem !important;
  color: #000;
}

.control.has-icons-right .input {
  padding-right: 4em !important;
}

.predictionsResults {
  padding: 0 1.5rem !important;
}

.around-me-button {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
}

.around-me-button--active {
  color: #dc0c23 !important;
  font-weight: 500;
  font-size: 18px;
}

.around-me-label {
  padding-left: 11px;
}
.storesList {
  max-height: 400px;
  overflow-y: scroll;
}

.storesList__storePreview {
  cursor: pointer;
  border-top: 1px solid;
  border-color: rgb(241, 241, 241);
  padding: 10px;
  display: flex;
}

.storesList__storePreview:hover {
  background: rgb(241, 241, 241);
}

.container {
  margin: 2px 10px !important;
  border-bottom: 1px solid #ccc !important;
}

.storePreview__storeDistance--distance {
  text-align: center;
}

.storePreview__storeDistance--icon {
  text-align: center;
}

.storePreview {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid #ccc;
  cursor: pointer;
}

.storePreview__storeName {
  font-weight: 500;
  font-size: 16px;
}

.storePreview__storeAddressLines {
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  .storePreview__storeName {
    font-weight: 500;
    font-size: 1rem;
  }

  .storePreview__storeAddressLines {
    font-size: 0.74rem;
  }
}

.Collapsible {
  padding: 0 24px !important;
}
.Collapsible__trigger {
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}

.Collapsible__contentInner {
  /* margin-top: 10px; */
}

.open-close-icon {
  position: absolute;
  right: 14px;
}

.categories {
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
  margin-top: 5px;
}

.categories-logo {
  padding: 10px;
  border: 1px solid #6e3cbe;
  border-radius: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .categories-logo {
    width: 50%;
  }
}

.categories-logo--KDIT-Gold {
  border: 1px solid #f5b400;
}

.categories-logo--KDIT-Silver {
  border: 1px solid #585858;
}

.categories-logo--KDIT-Platinum {
  border: 1px solid #6e3cbe;
}

.categories-logo--KDIT-KEP {
  border: 1px solid #f06400;
}

.categories-logo--KDIT-Supply-Distributor {
  border: 1px solid #282828;
}

.categories-logo--KDIT-Production-Printing-Partner {
  border: 1px solid #0a9bcd;
}

.categories-logo--active {
  background-color: #6e3cbe;
}

.categories-logo--active--KDIT-Gold {
  background-color: #f5b400;
}

.categories-logo--active--KDIT-Silver {
  background-color: #585858;
}

.categories-logo--active--KDIT-Platinum {
  background-color: #6e3cbe;
}

.categories-logo--active--KDIT-KEP {
  background-color: #f06400;
}

.categories-logo--active--KDIT-Supply-Distributor {
  background-color: #282828;
}

.categories-logo--active--KDIT-HW-Distributor {
  background-color: #0a9bcd;
}
.certificates {
  font-size: 14px;
  color: #000;
  margin-bottom: 1px;
}
.certificates-line {
  margin: 5px 0;
}

.certificates-name {
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.certificates-name--active {
  background-color: rgba(233, 80, 14, 0.1);
}

body {
  height: 100%;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  font-family: "GT-Eesti";
  letter-spacing: -0.01em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.storeLocator {
}

.header {
  z-index: 100;
  background: #fff;
}

.header-container {
  width: 100%;
  height: 100%;
}

.first-row {
  padding: 30px;
  width: 100%;
  margin: 0;
}

.first-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 90px;
}

.logo-company {
  cursor: pointer;
}

.divider {
  width: 100%;
  border-top: 1px solid #ccc;
}

.bars-btn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
  .first-row-container {
    margin: 0;
  }
}
/* FONTS */

/* NAVBAR */
.navbar {
    font-family: 'Montserrat', sans-serif;
    padding: 0 !important;
}

.navbar-nav {
    flex-wrap: wrap;
    justify-content: space-between;
}

.nav-link {
    color: #333333 !important;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: normal;
    height: 52px;
    display: flex !important;
    align-items: center !important;
}

.nav-link:hover {
    color: #e9500e !important;
    font-size: 15px;
    height: 51px;
    border-top: 1px solid #e9500e;
}

.container {
    border-bottom: none !important;
    max-width: 1200px !important;
    margin: 0 auto !important;
}

.dropdown-menu.show {
    padding-top: 0 !important;
    border-top: 2px solid #e9500e !important;
}

.dropdown-item {
    font-size: 13px !important;
}

.dropdown-item:first-child {
    border-top: 1px solid #e9500e;
    padding-top: 8px;
}

.dropdown-item:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.dropdown-item:hover {
    background-color: transparent !important;
}

#storeLink:hover .shopping-icon {
    color: #000;
}

.shopping-icon {
    color: #e9500e;
    margin-right: 0.5rem;
}

@media only screen and (max-width: 1080px) and (min-width: 801px) {
    .navbar-nav {
      flex-wrap: wrap;
      justify-content: center;
    }
}
  
/* COLLAPSE MENU */
#vertical-menu {
    margin-bottom: 20px;
}

.accordion-main-link {
    background-color: #f9f9f9;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 0 1rem !important;
    height: 35px;
    justify-content: center;
}

.accordion-main-link:first-child {
    border-top: 1px solid #e9e9e9;
}

.accordion-main-link:focus {
    background-color: #f9f9f9;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 0 1rem !important;
    height: 35px;
    justify-content: center;
    color: #000 !important;
}

.border-bottom {
    border-bottom: 1px solid #e9e9e9;
}

.accordion-button {
    background-color: #f9f9f9 !important;
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    padding: 0 1rem !important;
    height: 35px !important;
    justify-content: center !important;
}

.accordion-button::after {
    margin-left: 0 !important;
    position: absolute;
    right: 20px;
    background-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
}

.accordion-body {
    padding: 0 !important;
}

.accordion-nav {
    flex-direction: column;
}

.accordion-nav-item {
    background-color: #f9f9f9 !important;
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    padding: 0 1rem !important;
    height: 35px !important;
    justify-content: center !important;
    text-align: center;
}

.accordion-nav-item:not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
}
/* INPUT SEARCH */
.input-group {
    height: 50px;
  }
  
  .form-control::-webkit-input-placeholder {
    color: #9ea0a4 !important;
  }
  
  .form-control::placeholder {
    color: #9ea0a4 !important;
  }
  
  .form-control:focus {
    border: 1px solid #e2e2e2 !important;
    border-right: 1px solid transparent !important;
    box-shadow: none !important;
  }
  
  .form-control {
    font-family: 'Open Sans', sans-serif;
    color: #9ea0a4 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  
  .search-bar {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }

  .search-button {
    width: 50px;
    background-color: #000 !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  
  .search-button:hover {
    background-color: #e9500e !important;
    width: 50px;
  }

  .list-group {
    position: absolute; 
    top: 50px; 
    z-index: 100; 
    width: 100%;
    border-radius: 6px !important;
    max-height: 250px;
    overflow: auto;
  }
  
  .list-group-item {
    border-color: #65bc7b !important;
    display: flex !important;
    align-items: center !important;
    padding: 14px 7px !important;
    line-height: 1.4 !important;
    border-bottom-style: solid !important;
    text-align: initial !important;
    cursor: pointer !important;
    transition: .2s padding !important;
  }

.image-container {
    max-width: 45px;
    box-sizing: content-box;
    padding: 0 7px;
}

.image-container-img {
    width: 100%;
}

.description-container {
    padding: 0 7px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
}

.description-container-title {
    font-size: 16px;
}

.description-container-type {
    font-size: 14px;
}
footer {
  position: absolute;
  clear: both;
  width: 100%;
  height: auto;
  background: #e9500e;
  min-height: 40px;
  font-family: 'Open Sans', sans-serif;
}

.footer-container {
  padding: 18px 0;
  width: 85%;
  margin: 0 auto;
}

.footer-socials {
  display: flex !important;
  align-items: center;
  justify-content: right;
}

.footer-socials--icons {
  margin: 0 10px;
  font-size: 16px;
}

.footer-copyright {
  display: inline;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 0 10px;
}

.footer-copyright--top {
  margin: 12px 0;
}
.footer-copyright--bottom {
  margin-bottom: 12px;
}

.footer-copyright--link {
  text-decoration: underline;
  color: #fff;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    background: #e9500e;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-container {
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
  }

  .footer-copyright {
    text-align: center;
  }

  .footer-socials {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .footer-socials--icons {
    margin: 0 10px;
    font-size: 16px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    background: #e9500e;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-container {
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
  }

  .footer-copyright {
    text-align: center;
  }

  .footer-socials {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .footer-socials--icons {
    margin: 0 10px;
    font-size: 16px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    background: #e9500e;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-container {
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
  }

  .footer-socials {
    display: flex !important;
    align-items: center;
    justify-content: right;
  }

  .footer-socials--icons {
    margin: 0 10px;
    font-size: 16px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  footer {
    position: absolute;
    clear: both;
    width: 100%;
    height: auto;
    background: #e9500e;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
  }

  .footer-container {
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
  }

  .footer-socials {
    display: flex !important;
    align-items: center;
    justify-content: right;
  }

  .footer-socials--icons {
    margin: 0 10px;
    font-size: 16px;
  }

  .footer-links {
    display: block;
    margin: 0 10px;
    font-size: 1rem;
  }

  .footer-link {
    color: #000;
    text-decoration: none;
  }
}

/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 1500px) {
  .footer-links {
    display: inline;
    margin: 0 10px;
    font-size: 1rem;
  }
}

