@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

/* INPUT SEARCH */
.input-group {
    height: 50px;
  }
  
  .form-control::placeholder {
    color: #9ea0a4 !important;
  }
  
  .form-control:focus {
    border: 1px solid #e2e2e2 !important;
    border-right: 1px solid transparent !important;
    box-shadow: none !important;
  }
  
  .form-control {
    font-family: 'Open Sans', sans-serif;
    color: #9ea0a4 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  
  .search-bar {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }

  .search-button {
    width: 50px;
    background-color: #000 !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  
  .search-button:hover {
    background-color: #e9500e !important;
    width: 50px;
  }

  .list-group {
    position: absolute; 
    top: 50px; 
    z-index: 100; 
    width: 100%;
    border-radius: 6px !important;
    max-height: 250px;
    overflow: auto;
  }
  
  .list-group-item {
    border-color: #65bc7b !important;
    display: flex !important;
    align-items: center !important;
    padding: 14px 7px !important;
    line-height: 1.4 !important;
    border-bottom-style: solid !important;
    text-align: initial !important;
    cursor: pointer !important;
    transition: .2s padding !important;
  }

.image-container {
    max-width: 45px;
    box-sizing: content-box;
    padding: 0 7px;
}

.image-container-img {
    width: 100%;
}

.description-container {
    padding: 0 7px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
}

.description-container-title {
    font-size: 16px;
}

.description-container-type {
    font-size: 14px;
}