.around-me-button {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
}

.around-me-button--active {
  color: #dc0c23 !important;
  font-weight: 500;
  font-size: 18px;
}

.around-me-label {
  padding-left: 11px;
}