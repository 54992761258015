@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

/* COLLAPSE MENU */
#vertical-menu {
    margin-bottom: 20px;
}

.accordion-main-link {
    background-color: #f9f9f9;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 0 1rem !important;
    height: 35px;
    justify-content: center;
}

.accordion-main-link:first-child {
    border-top: 1px solid #e9e9e9;
}

.accordion-main-link:focus {
    background-color: #f9f9f9;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 0 1rem !important;
    height: 35px;
    justify-content: center;
    color: #000 !important;
}

.border-bottom {
    border-bottom: 1px solid #e9e9e9;
}

.accordion-button {
    background-color: #f9f9f9 !important;
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    padding: 0 1rem !important;
    height: 35px !important;
    justify-content: center !important;
}

.accordion-button::after {
    margin-left: 0 !important;
    position: absolute;
    right: 20px;
    background-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
}

.accordion-body {
    padding: 0 !important;
}

.accordion-nav {
    flex-direction: column;
}

.accordion-nav-item {
    background-color: #f9f9f9 !important;
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    padding: 0 1rem !important;
    height: 35px !important;
    justify-content: center !important;
    text-align: center;
}

.accordion-nav-item:not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
}