.header {
  z-index: 100;
  background: #fff;
}

.header-container {
  width: 100%;
  height: 100%;
}

.first-row {
  padding: 30px;
  width: 100%;
  margin: 0;
}

.first-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 90px;
}

.logo-company {
  cursor: pointer;
}

.divider {
  width: 100%;
  border-top: 1px solid #ccc;
}

.bars-btn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
  .first-row-container {
    margin: 0;
  }
}